









import Vue from "vue";
import OrderDetails from "@/components/inventory/OrderDetails.vue";
import ActionButtons from "@/components/ActionButtons.vue";

export default Vue.extend({
  name: "OrderPage",
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  components: { OrderDetails, ActionButtons },
});
